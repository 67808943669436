import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const ProductCardStyled = styled.div`
  background: var(--white);
  border: 1px solid var(--blackMatte);
  color: var(--blackMatte);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  text-align: left !important;
  a {
    color: var(--borderColor);
    text-decoration: none;
  }
  a:hover {
    cursor: pointer;
  }
  .product-image {
    display: grid;
    align-items: center;
    justify-items: center;
    height: auto;
    width: 100%;
  }
  .category {
    padding: 1.5rem;
  }
  h4,
  .product-explore {
    padding: 1.5rem 1.5rem 1.5rem 0;
    margin-left: 1.5rem;
    border-top: 1px solid var(--blackMatte);
  }
  h4 {
    font-size: 1.8rem;
  }
  .product-explore {
    margin-top: auto;
  }
  .product-excerpt {
    padding: 0 1.5rem 1.5rem 0;
    margin-left: 1.5rem;
    max-height: 80px;
    overflow: hidden;
    box-sizing: content-box;
  }
  .category {
    border-top: 1px solid var(--blackMatte);
    display: block;
    text-transform: uppercase;
  }
`;

export default function ProductDrawer(props) {
  const truncateBy = 88;
  const desc = `${
    props.description
      ? props.description
      : "Dedicated to producing the world's most technically advanced and anatomically correct cycling shoes."
  }`;
  const truncatedDesc = desc.substring(0, truncateBy);
  return (
    <ProductCardStyled
      data-sortable
      data-sort-gender={props?.gender}
      data-sort-series={props?.series.toLowerCase()}
      data-sort-type={props?.type}
    >
      <Link to={`/products/${props.slug}`}>
        <Img
          fluid={props.image}
          alt={props.title}
          className="product-image"
          imgStyle={{ objectFit: 'contain' }}
          aspectRatio={5 / 3}
        />
      </Link>
      <span className="category">{props.category}</span>
      <h4>{props.title}</h4>
      <div className="product-excerpt">{truncatedDesc}...</div>
      <div className="product-explore">
        <Link to={`/products/${props.slug}`}>Explore the {props.title}</Link>
      </div>
    </ProductCardStyled>
  );
}
