import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Img } from 'gatsby-image';
import styled from 'styled-components';
import { VscClose } from '@react-icons/all-files/vsc/VscClose';
import { RiCheckboxMultipleFill as FilterIcon } from '@react-icons/all-files/ri/RiCheckboxMultipleFill';
import { RiCloseCircleLine as FilterOffIcon } from '@react-icons/all-files/ri/RiCloseCircleLine';
import ProductCard from '../components/ProductCard';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const CategoriesStyled = styled.div`
  width: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: 1023px) {
    background-size: 100% contain;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(to bottom, var(--greyLight), transparent);
    z-index: 0;
  }
  .categories-container {
    max-width: var(--wrapper);
    margin: 0 auto;
    h1 {
      color: var(--white);
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.75);
      @media (max-width: 1023px) {
        font-size: 3.5rem;
      }
    }
    z-index: 1;
    position: relative;
  }
  .category-header {
    min-height: 400px;
    display: grid;
    align-items: center;
    justify-items: center;
    h1 {
      z-index: 1;
    }
    @media (max-width: 1023px) {
      min-height: 200px;
    }
  }
  .category-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
    .hidden {
      display: none;
    }
  }
  .product-filter-settings {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    div {
      background: var(--backgroundCard);
      padding: 0.5rem 0.9rem;
      border-radius: calc(var(--borderRadius) * 6);
      cursor: pointer;
      box-shadow: var(--boxShadow);
    }
    font-size: 1.15rem;
    @media (max-width: 1023px) {
      font-size: 1rem;
    }
    svg {
      font-size: 1.15rem;
      vertical-align: middle;
      margin-left: 0.25rem;
      @media (max-width: 1023px) {
        font-size: 1rem;
      }
    }
  }
  .product-filters {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    align-items: center;
    justify-content: space-evenly;
    background: var(--whiteTrans);
    margin-bottom: 1rem;
    border-radius: var(--borderRadius);
    @media (max-width: 1023px) {
      flex-direction: column;
    }
    &.active {
      display: grid;
    }
    .product-filters-lists {
      background: var(--backgroundCard);
      box-shadow: var(--boxShadow);
      border-radius: var(--borderRadius);
      display: grid;
      padding: 1.5rem;
      align-items: center;
      gap: 1rem;
      width: 100%;
      max-width: calc(400px - 2rem);
      position: relative;
      p {
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1rem;
        &.title {
          font-size: 1.25rem;
        }
      }
    }
    .close-filters {
      font-size: 2rem;
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    ul {
      list-style: none;
      align-items: center;
      padding: 0;
      gap: 1rem;
      margin-bottom: 0;
    }
    li {
      font-size: 1.15rem;
      margin: 0.5rem 0.5rem 1rem 0;
      margin-bottom: 1rem;
      display: inline-block;
    }
    input[type='radio'] {
      opacity: 0.01;
      position: absolute;
      margin-bottom: 0;
    }
    .filter,
    .filter-reset {
      padding: 0.5rem 1rem;
      color: var(--textColor);
      text-transform: uppercase;
      position: relative;
      z-index: 1;
      cursor: pointer;
    }
    .filter-reset {
      cursor: pointer;
      text-transform: none;
      font-size: 1.15rem;
      svg {
        font-size: 1.15rem;
        vertical-align: middle;
        margin-left: 0.25rem;
      }
    }
    label {
      cursor: pointer;
      padding: 0.5rem 1rem;
      color: var(--textColor);
      border: 1px solid var(--greyLight);
      border-radius: 20px;
      position: relative;
      z-index: 1;
      transition: 0.5s;
      text-transform: capitalize;
    }
    input[type='radio']:checked + label {
      background: var(--bont);
      border-color: var(--bont);
      color: var(--white);
    }
  }
`;

export default function SingleProductPage({ data, pageContext }) {
  const { products } = data;
  const [filter, setFilter] = useState({});
  const [productList, setProducts] = useState(products.nodes);
  const [filterState, filterToggle] = useState(false);

  function toggleFilters() {
    filterToggle(!filterState);
  }

  function handleGenderChange(e) {
    setFilter({
      ...filter,
      gender: e.target.value.toString(),
    });
  }
  function handleSeriesChange(e) {
    setFilter({
      ...filter,
      series: e.target.value.toString(),
    });
  }
  function handleTypeChange(e) {
    setFilter({
      ...filter,
      type: e.target.value.toString(),
    });
  }

  const filterByGender = (filteredData) => {
    // Avoid filter for empty string
    if (!filter.gender) {
      return filteredData;
    }

    const filteredProducts = filteredData.filter(
      (item) => item.gender === filter.gender
    );
    return filteredProducts;
  };
  const filterBySeries = (filteredData) => {
    // Avoid filter for null value
    if (!filter.series) {
      return filteredData;
    }

    const filteredProducts = filteredData.filter(
      (item) => item.series.title.toLowerCase() === filter.series
    );
    return filteredProducts;
  };
  const filterByType = (filteredData) => {
    // Avoid filter for null value
    if (!filter.type) {
      return filteredData;
    }

    const filteredProducts = filteredData.filter(
      (item) => item.productType.title.toLowerCase() === filter.type
    );
    return filteredProducts;
  };
  useEffect(() => {
    let filteredData = filterByGender(products.nodes);
    filteredData = filterBySeries(filteredData);
    filteredData = filterByType(filteredData);
    setProducts(filteredData);
  }, [setProducts, filter]);

  const clearFilters = () => {
    setFilter({});
  };

  function clearRadios() {
    const ele = document.querySelectorAll('input[type="radio"]');
    for (let i = 0; i < ele.length; i++) ele[i].checked = false;
    clearFilters();
  }

  const filterGenders = products.nodes.reduce((acc, currentValue) => {
    if (!currentValue.gender) {
      return acc;
    }
    const genderFound = acc.find((a) => a === currentValue.gender);
    const value = currentValue.gender;
    if (!genderFound) {
      acc.push(value);
    }
    return acc;
  }, []);

  const filterSeries = products.nodes.reduce((acc, currentValue) => {
    if (!currentValue.series?.title) {
      return acc;
    }
    const seriesFound = acc.find(
      (a) => a === currentValue.series.title.toLowerCase()
    );
    const value = currentValue.series.title.toLowerCase();
    if (!seriesFound) {
      acc.push(value);
    }
    return acc;
  }, []);

  const filterType = products.nodes.reduce((acc, currentValue) => {
    if (!currentValue.productType?.title) {
      return acc;
    }
    const typeFound = acc.find(
      (a) => a === currentValue.productType.title.toLowerCase()
    );
    const value = currentValue.productType.title.toLowerCase();
    if (!typeFound) {
      acc.push(value);
    }
    return acc;
  }, []);
  return (
    <>
      <SEO title={`Explore our ${pageContext.name}`} />
      <CategoriesStyled
        style={{
          backgroundImage: `url(${
            pageContext.background ? pageContext.background : ''
          })`,
        }}
      >
        <div className="category-container wrapper">
          <div className="category-header">
            <h1>{pageContext.name}</h1>
          </div>
          {pageContext.name === 'Apparel' && (
            <>
              <div className="product-filter-settings">
                <div className="filter">
                  <span
                    role="button"
                    className="filter-button"
                    onClick={toggleFilters}
                  >
                    Filters {filter.gender ? filter.gender : ''}{' '}
                    {filter.series ? filter.series : ''}{' '}
                    {filter.type ? filter.type : ''}
                    <FilterIcon />
                  </span>
                </div>
                <div className="filter-reset" onClick={clearRadios}>
                  Reset filters
                  <FilterOffIcon />
                </div>
              </div>
              <div
                className={
                  filterState ? 'product-filters active' : 'product-filters'
                }
              >
                <div className="product-filters-lists">
                  <VscClose className="close-filters" onClick={toggleFilters} />
                  <div>
                    <p className="title">Filter by</p>
                  </div>
                  <div>
                    <p>Gender</p>
                    <ul>
                      {filterGenders.map((item) => (
                        <li>
                          <input
                            type="radio"
                            id={item}
                            name="gender"
                            value={item}
                            onClick={handleGenderChange}
                          />
                          <label htmlFor={item}>{item}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <p>Series</p>
                    <ul>
                      {filterSeries.map((item) => (
                        <li>
                          <input
                            type="radio"
                            id={item}
                            name="series"
                            value={item}
                            onClick={handleSeriesChange}
                          />
                          <label htmlFor={item}>{item}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <p>Product type</p>
                    <ul>
                      {filterType.map((item) => (
                        <li>
                          <input
                            type="radio"
                            id={item}
                            name="type"
                            value={item}
                            onClick={handleTypeChange}
                          />
                          <label htmlFor={item}>{item}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <div className="filter-reset" onClick={clearRadios}>
                      Reset filters <FilterOffIcon />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="category-wrapper">
            {productList.map((filteredProduct) => (
              <ProductCard
                title={filteredProduct.title}
                description={filteredProduct.description}
                image={filteredProduct.productImage.asset.fluid}
                series={filteredProduct.series.title}
                category={filteredProduct.category.title}
                slug={filteredProduct.slug.current}
                gender={filteredProduct?.gender}
                type={filteredProduct.productType?.title.toLowerCase()}
              />
            ))}
          </div>
        </div>
        <NavPages />
      </CategoriesStyled>
    </>
  );
}

export const query = graphql`
  query ($name: String!) {
    products: allSanityProducts(
      filter: { category: { title: { eq: $name, ne: "discontinued" } } }
      sort: { fields: [productPrice, title], order: [DESC, DESC, DESC] }
    ) {
      nodes {
        title
        id
        description
        slug {
          current
        }
        series {
          id
          title
          slug {
            current
          }
        }
        productType {
          slug {
            current
          }
          title
        }
        gender
        category {
          id
          title
          slug {
            current
          }
        }
        productImage {
          asset {
            fluid(maxWidth: 1000, maxHeight: 750) {
              ...GatsbySanityImageFluid
              srcWebp
            }
          }
        }
      }
    }
  }
`;
